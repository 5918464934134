import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { AboutComponent } from "./pages/about/about.component";
import { ResumeComponent } from "./pages/resume/resume.component";
import { ProjectsComponent } from "./pages/projects/projects.component";
import { ContactComponent } from './pages/contact/contact.component';

export const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "home",
    component: HomeComponent,
  },
  {
    path: "about",
    component: AboutComponent,
  },
  {
    path: "resume",
    component: ResumeComponent,
  },
  {
    path: "projects",
    component: ProjectsComponent,
  },
  {
    path: "contact",
    component: ContactComponent,
  },
  {
    path: "**",
    redirectTo: "home",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
