import { Component, OnInit } from "@angular/core";
import { SHOWCASE_URL } from "src/app/constants";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "projects",
  templateUrl: "./projects.component.html",
  styleUrls: ["./projects.component.scss"],
})
export class ProjectsComponent implements OnInit {
  pageAnimationClasses = "animate__animated animate__zoomIn";

  showcaseURL = SHOWCASE_URL;

  embedURL: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.embedURL =  this.sanitizer.bypassSecurityTrustResourceUrl(this.showcaseURL.SLICK_CHAT_YT);
  }

  openTab(url: string) {
    window.open(url);
  }
}
