import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import {
  Router,
  NavigationStart,
  NavigationEnd,
  Routes,
} from "@angular/router";
import { routes } from "./app-routing.module";
import * as _ from "lodash";
import { StyleManagerService } from "./services/style-manager.service";
import { AppTheme } from "./constants";
import { SecureStorageService } from "./services/secure-storage.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  showPreLoader = true;
  fadePreLoader = false;
  styleSheetUrl: SafeResourceUrl;
  showMobileNav = false;

  // routes length - 2 to temporarily remove access to Contact Me page
  routes: Routes = routes.slice(1, routes.length - 2).map((route) => route);
  currentRouteIndex: number;

  currentTheme: AppTheme = AppTheme.LIGHT;
  appTheme = AppTheme;

  constructor(
    sanitizer: DomSanitizer,
    private router: Router,
    private styleManager: StyleManagerService,
    private secureStorageService: SecureStorageService
  ) {
    this.initAppTheme();

    setTimeout(() => {
      this.fadePreLoader = true;
    }, 2000);
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRouteIndex = _.findIndex(this.routes, {
          path: event.url.slice(1),
        });
        this.currentRouteIndex =
          this.currentTheme < 0 ? 0 : this.currentRouteIndex;
      }
    });
  }

  initAppTheme() {
    const preferredTheme = this.secureStorageService.getValue("theme");

    if (preferredTheme === undefined) {
      this.currentTheme = AppTheme.LIGHT;
    } else {
      this.currentTheme = preferredTheme || AppTheme.LIGHT;
    }

    this.setAppTheme();
  }

  setAppTheme() {
    if (this.currentTheme === AppTheme.LIGHT) {
      this.styleManager.setStyle(
        "main-theme",
        "../assets/styles/main-light.css"
      );
    } else {
      this.styleManager.setStyle(
        "main-theme",
        "../assets/styles/main-dark.css"
      );
    }
  }

  animationEnd() {
    this.showPreLoader = false;
  }

  nextPage() {
    this.currentRouteIndex =
      this.currentRouteIndex < 0 ? 0 : this.currentRouteIndex;
    const destinationRoute =
      this.routes[(this.currentRouteIndex + 1) % this.routes.length].path;
    this.router.navigate([`/${destinationRoute}`]);
  }

  previousPage() {
    this.currentRouteIndex =
      this.currentRouteIndex < 0 ? 0 : this.currentRouteIndex;
    this.currentRouteIndex =
      this.currentRouteIndex == 0 ? this.routes.length : this.currentRouteIndex;
    const destinationRoute =
      this.routes[(this.currentRouteIndex - 1) % this.routes.length].path;
    this.router.navigate([`/${destinationRoute}`]);
  }

  toggleMobileNav() {
    this.showMobileNav = !this.showMobileNav;
  }

  toggleAppTheme() {
    this.currentTheme =
      this.currentTheme === AppTheme.LIGHT ? AppTheme.DARK : AppTheme.LIGHT;

    this.secureStorageService.setValue("theme", this.currentTheme);

    this.setAppTheme();
  }
}
