<div class="content-area" [ngClass]="pageAnimationClasses">
  <div class="animated-sections">
    <section
      data-id="resume"
      class="animated-section start-page section-active"
    >
      <div class="page-title">
        <h2>Resume</h2>
      </div>

      <div class="section-content">
        <div class="row">
          <div class="col-xs-12 col-sm-7">
            <div class="block-title">
              <h3>Education</h3>
            </div>

            <div class="timeline timeline-second-style clearfix">
              <div class="timeline-item clearfix">
                <div class="left-part">
                  <h5 class="item-period">Northeastern University</h5>
                  <span class="item-company">2021</span>
                </div>
                <div class="divider"></div>
                <div class="right-part">
                  <h4 class="item-title">
                    Master of Science in Computer Science
                  </h4>
                  <p>
                    MSCS from Khoury College of Computer Sciences, Northeastern
                    University with GPA of 3.5/4.0 proficient in Software
                    Engineering, Web Development, Programming Design Paradigms
                    and Algorithms.
                  </p>
                </div>
              </div>

              <div class="timeline-item clearfix">
                <div class="left-part">
                  <h5 class="item-period">University of Mumbai</h5>
                  <span class="item-company">2017</span>
                </div>
                <div class="divider"></div>
                <div class="right-part">
                  <h4 class="item-title">
                    Bachelor of Engineering in Computer Engineering
                  </h4>
                  <p>
                    Graduated with 4 year undergraduate course in Computer
                    Engineering with proficiency in Data Structures, Distributed
                    systems, Object Oriented Programming, Analysis of
                    Algorithms, Database Management, Operating Systems, Computer
                    Networks and Web Technologies.
                  </p>
                </div>
              </div>
            </div>

            <div class="white-space-50"></div>

            <div class="block-title">
              <h3>Experience</h3>
            </div>

            <div class="timeline timeline-second-style clearfix">
              <div class="timeline-item clearfix">
                <div class="left-part">
                  <h5 class="item-period">Salesforce</h5>
                  <span class="item-company">Feb 2022 - Present</span>
                </div>
                <div class="divider"></div>
                <div class="right-part">
                  <h4 class="item-title">Software Engineer MTS</h4>
                  <p>
                    - Software engineer on the React DC team at Salsforce that
                    work on PWA Kit. The PWA Kit is a storefront technology for
                    headless commerce using Salesforce Commerce APIs and React.
                    It provides front-end developers with a more flexible and
                    agile approach to build and maintain modern shopping
                    experiences. It is open source and actively maintained.
                  </p>
                  <p>
                    - Responsible for actively maintaining SFRA on Commerce
                    Cloud. Commerce Cloud provides merchants with a reference
                    architecture to serve as a framework for website design.
                    Storefront Reference Architecture (SFRA) was engineered
                    through a data-driven design analysis of over 2,000 mobile
                    storefronts to identify optimal UX approaches.
                  </p>
                </div>
              </div>

              <div class="timeline-item clearfix">
                <div class="left-part">
                  <h5 class="item-period">Salesforce</h5>
                  <span class="item-company">May 2021 - August 2021</span>
                </div>
                <div class="divider"></div>
                <div class="right-part">
                  <h4 class="item-title">Software Engineering Intern</h4>
                  <p>
                    - Contributed towards building the PWA Kit and Retail React
                    App within Commerce Cloud by implementing react hooks and
                    isomorphic sdk to build wishlist workflow using best
                    practices and leveraging headless storefronts.
                  </p>
                  <p>
                    - PWA Kit provides components and ecommerce flows with
                    built-in best practices to help brands build a modern web
                    experience that’s fully customizable. With a headless
                    approach, businesses can own their brand experience and
                    build whatever and however they want, which enables richer
                    experiences and commerce anywhere.
                  </p>
                </div>
              </div>

              <div class="timeline-item clearfix">
                <div class="left-part">
                  <h5 class="item-period">CVS Health</h5>
                  <span class="item-company">May 2020 - Dec 2020</span>
                </div>
                <div class="divider"></div>
                <div class="right-part">
                  <h4 class="item-title">Software Engineering Co-Op</h4>
                  <p>
                    - Built a questionnaire builder used by pharmacists to
                    assist patients, record responses and suggest medication as
                    a part of the Retail offer management software.
                  </p>
                  <p>
                    - Implemented a message configuration tool used by the data
                    warehouse team and message delivery management tool to
                    configure messages sent to 23 million CVS patients to
                    facilitate business via SMS.
                  </p>
                </div>
              </div>

              <div class="timeline-item clearfix">
                <div class="left-part">
                  <h5 class="item-period">Northeastern University</h5>
                  <span class="item-company">Jan. 2020 - Apr. 2020</span>
                </div>
                <div class="divider"></div>
                <div class="right-part">
                  <h4 class="item-title">Graduate Teaching Assistant</h4>
                  <p>
                    - Assisted Prof. Jose Annunziato for Web Development course
                    by hosting office hours, grading assignments and proctoring
                    exams for courses - CS4550 and CS5610 with a combined class
                    strength of 200 students.
                  </p>
                  <p>
                    - Mentored students in learning web techonologies like
                    React, Angular, Node.js, MongoDB, Java Springboot, MySQL and
                    deployment to heroku and AWS as a part of the course
                    curriculum.
                  </p>
                </div>
              </div>

              <div class="timeline-item clearfix">
                <div class="left-part">
                  <h5 class="item-period">Faclon Labs Pvt. Ltd.</h5>
                  <span class="item-company">Jun. 2017 - Aug. 2019</span>
                </div>
                <div class="divider"></div>
                <div class="right-part">
                  <h4 class="item-title">Software Developer II</h4>
                  <p>
                    - Built a data management and visualization platform in
                    Node.js, InfluxDB, MongoDB and lead front-end application
                    development in Angular and Typescript while managing all
                    facets of product development.
                  </p>
                  <p>
                    - Implemented features used for monitoring various
                    industrial processes including asset monitoring, predictive
                    maintenance, automation, water and energy management.
                  </p>
                </div>
              </div>

              <div class="timeline-item clearfix">
                <div class="left-part">
                  <h5 class="item-period">Aurionpro Solutions</h5>
                  <span class="item-company">Jun. 2016 - Feb. 2017</span>
                </div>
                <div class="divider"></div>
                <div class="right-part">
                  <h4 class="item-title">Software Development Intern</h4>
                  <p>
                    - Designed and developed a hybrid mobile app for cash
                    management platform with Ionic Framework and JavaScript and
                    offered a native mobile app experience on iOS and Android to
                    reach a wider userbase.
                  </p>
                  <p>
                    - Redesigned a code generator app implementing Spring MVC
                    and Angular to generate boilerplate java code required for
                    and mapping SQL tables with Java classes resulting in boost
                    in developer productivity.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- Skills & Certificates -->
          <div class="col-xs-12 col-sm-5">
            <!-- Design Skills -->
            <div class="block-title">
              <h3>Design <span>Skills</span></h3>
            </div>

            <div class="skills-info skills-second-style">
              <!-- Skill 6 -->
              <div class="skill clearfix">
                <h4>Web Design</h4>
                <div class="skill-value">90%</div>
              </div>
              <div class="skill-container skill-6">
                <div class="skill-percentage"></div>
              </div>
              <!-- End of Skill 6 -->

              <!-- Skill 7 -->
              <div class="skill clearfix">
                <h4>Print Design</h4>
                <div class="skill-value">60%</div>
              </div>
              <div class="skill-container skill-7">
                <div class="skill-percentage"></div>
              </div>
              <!-- End of Skill 7 -->

              <!-- Skill 8 -->
              <div class="skill clearfix">
                <h4>System Design</h4>
                <div class="skill-value">75%</div>
              </div>
              <div class="skill-container skill-8">
                <div class="skill-percentage"></div>
              </div>
              <!-- End of Skill 8 -->
            </div>
            <!-- End of Design Skills -->

            <!-- Coding Skills -->
            <div class="block-title">
              <h3>Coding <span>Skills</span></h3>
            </div>

            <div class="skills-info skills-second-style">
              <!-- Skill 1 -->
              <div class="skill clearfix">
                <h4>JavaScript</h4>
                <div class="skill-value">85%</div>
              </div>
              <div class="skill-container skill-1">
                <div class="skill-percentage"></div>
              </div>
              <!-- End of Skill 1 -->

              <!-- Skill 2 -->
              <div class="skill clearfix">
                <h4>Typescript</h4>
                <div class="skill-value">85%</div>
              </div>
              <div class="skill-container skill-2">
                <div class="skill-percentage"></div>
              </div>
              <!-- End of Skill 2 -->

              <!-- Skill 3 -->
              <div class="skill clearfix">
                <h4>Angular / React</h4>
                <div class="skill-value">90%</div>
              </div>
              <div class="skill-container skill-3">
                <div class="skill-percentage"></div>
              </div>
              <!-- End of Skill 3 -->

              <!-- Skill 4 -->
              <div class="skill clearfix">
                <h4>Java</h4>
                <div class="skill-value">85%</div>
              </div>
              <div class="skill-container skill-4">
                <div class="skill-percentage"></div>
              </div>
              <!-- End of Skill 4 -->

              <!-- Skill 5 -->
              <div class="skill clearfix">
                <h4>NodeJS</h4>
                <div class="skill-value">90%</div>
              </div>
              <div class="skill-container skill-5">
                <div class="skill-percentage"></div>
              </div>
              <!-- End of Skill 5 -->
            </div>
            <!-- End of Coding Skills -->

            <div class="white-space-10"></div>

            <!-- Knowledges -->
            <div class="block-title">
              <h3>Knowledges</h3>
            </div>

            <ul class="knowledges">
              <li>Web Development</li>
              <li>Full Stack</li>
              <li>Java</li>
              <li>Angular</li>
              <li>React</li>
              <li>React Native</li>
              <li>Redis DB</li>
              <li>Influx DB</li>
              <li>Mongo DB</li>
              <li>GraphQL</li>
              <li>System Design</li>
              <li>Algorithms & Data Structures</li>
              <li>Ionic</li>
              <li>Flutter</li>
            </ul>
            <!-- End of Knowledges -->
          </div>
          <!-- End of Skills & Certificates -->
        </div>

        <div class="white-space-50"></div>

        <!-- Certificates -->
        <div class="row">
          <div class="col-xs-12 col-sm-12">
            <div class="block-title">
              <h3>Certificates</h3>
            </div>
          </div>
        </div>

        <div class="row">
          <!-- Certificate 1 -->
          <div class="col-xs-12 col-sm-6">
            <div
              class="certificate-item clearfix pointer"
              (click)="viewCertificate()"
            >
              <div class="certi-logo">
                <img
                  src="https://media-exp1.licdn.com/dms/image/C560BAQH0VNXBrdkklA/company-logo_100_100/0?e=1603929600&v=beta&t=0oH0DyL6a1xzL_8bcy1zXt-qWhblebL7W7WRItYPVOk"
                  alt="logo"
                />
              </div>

              <div class="certi-content">
                <div class="certi-title">
                  <h4>Dart & Flutter Development</h4>
                </div>
                <div class="certi-id">
                  <span>Issued by Udemy</span>
                </div>
                <div class="certi-date">
                  <span>July 2019</span>
                </div>
                <div class="certi-company">
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <!-- End of Certificate 1 -->

          <!-- Certificate 2 -->
          <div class="col-xs-12 col-sm-6">
            <div class="certificate-item clearfix">
              <div class="certi-logo">
                <img src="../../../assets/img/certi/maac.png" alt="logo" />
              </div>

              <div class="certi-content">
                <div class="certi-title">
                  <h4>Graphic Design & Print Media</h4>
                </div>
                <div class="certi-id">
                  <span>Issued by Maya Academy of Advanced Cinematics</span>
                </div>
                <div class="certi-date">
                  <span>2017</span>
                </div>
                <div class="certi-company">
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <!-- End of Certificate 2 -->
        </div>
        <!-- End of Certificates -->
      </div>
    </section>
  </div>
</div>
