import { Injectable } from "@angular/core";
import * as SecureLS from "secure-ls";

@Injectable({
  providedIn: "root",
})
export class SecureStorageService {
  private ls = new SecureLS({ encodingType: "aes" });

  constructor() {}

  setValue(key: string, value: any): void {
    this.ls.set(key, value);
  }

  getValue(key: string): any {
    console.log(key, this.ls.get(key));
    return this.ls.get(key);
  }

  getAllKeys(): string[] {
    return this.ls.getAllKeys();
  }

  removeKey(key: string): void {
    this.ls.remove(key);
  }

  // Remove keys generated by secure-ls including meta key
  removeAllKeys(): void {
    this.ls.removeAll();
  }

  // Removes all keys ever created for a given domain
  clearStorage(): void {
    this.ls.clear();
  }
}
