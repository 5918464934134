<!-- <link rel="stylesheet" [href]="styleSheetUrl" /> -->
<div class="lm-animated-bg"></div>
<!-- /Animated Background -->

<!-- Loading animation -->
<div
  class="preloader"
  [ngClass]="{ 'animate__animated animate__fadeOut': fadePreLoader }"
  *ngIf="showPreLoader"
  (animationend)="animationEnd()"
>
  <div class="preloader-animation">
    <div class="preloader-spinner"></div>
  </div>
</div>
<!-- /Loading animation -->

<div
  class="page"
  *ngIf="currentTheme != null"
  [ngClass]="{
    'mat-dark-theme': currentTheme == appTheme.DARK,
    'mat-light-theme': currentTheme == appTheme.LIGHT
  }"
>
  <div class="page-content">
    <header
      id="site_header"
      class="header"
      [ngClass]="{ 'mobile-menu-hide': !showMobileNav, animate: showMobileNav }"
    >
      <div class="header-content">
        <div class="header-photo">
          <img src="./assets/img/main_photo.jpg" alt="Jainam Sheth" />
        </div>
        <div class="header-titles">
          <h2>Jainam Sheth</h2>
          <h4>Full-Stack Developer</h4>
        </div>
      </div>

      <ul class="main-menu" [ngClass]="{ open: showMobileNav }">
        <li class="active">
          <a
            [routerLink]="['/home']"
            routerLinkActive="active"
            class="nav-anim"
            (click)="toggleMobileNav()"
          >
            <span class="menu-icon lnr lnr-home"></span>
            <span class="link-text">Home</span>
          </a>
        </li>
        <li>
          <a
            [routerLink]="['/about']"
            routerLinkActive="active"
            class="nav-anim"
            (click)="toggleMobileNav()"
          >
            <span class="menu-icon lnr lnr-user"></span>
            <span class="link-text">About Me</span>
          </a>
        </li>
        <li>
          <a
            [routerLink]="['/resume']"
            routerLinkActive="active"
            class="nav-anim"
            (click)="toggleMobileNav()"
          >
            <span class="menu-icon lnr lnr-graduation-hat"></span>
            <span class="link-text">Resume</span>
          </a>
        </li>
        <li>
          <a
            [routerLink]="['/projects']"
            routerLinkActive="active"
            class="nav-anim"
            (click)="toggleMobileNav()"
          >
            <span class="menu-icon lnr lnr-briefcase"></span>
            <span class="link-text">Projects</span>
          </a>
        </li>
        <!-- <li>
          <a
            [routerLink]="['/contact']"
            routerLinkActive="active"
            class="nav-anim"
            (click)="toggleMobileNav()"
          >
            <span class="menu-icon lnr lnr-envelope"></span>
            <span class="link-text">Contact</span>
          </a>
        </li> -->
        <mat-divider class="d-none d-xl-block"></mat-divider>
        <li class="d-none d-xl-block">
          <a class="nav-anim pointer" (click)="toggleAppTheme()">
            <span
              class="menu-icon lnr"
              [ngClass]="{
                'lnr-sun': currentTheme == appTheme.DARK,
                'lnr-moon': currentTheme == appTheme.LIGHT
              }"
            ></span>
            <span class="link-text">{{
              currentTheme == appTheme.LIGHT ? "Dark Theme" : "Light Theme"
            }}</span>
          </a>
        </li>
      </ul>

      <div class="social-links">
        <ul>
          <li>
            <a href="https://www.linkedin.com/in/sheth-jainam" target="_blank"
              ><i class="fab fa-linkedin-in"></i
            ></a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/thiscodehasnoeffect/"
              target="_blank"
              ><i class="fab fa-instagram"></i
            ></a>
          </li>
        </ul>
      </div>

      <div class="header-buttons">
        <a
          href="../assets/docs/Resume_Jainam_Sheth.pdf"
          download
          class="btn btn-primary"
          >Download CV</a
        >
      </div>

      <div class="copyrights">
        © 2020 All rights reserved.
        <!-- <span class="d-block d-lg-none">
          <br />
          <i class="fab fa-sun-o"></i>
        </span> -->
      </div>
    </header>

    <!-- Mobile Navigation -->
    <div class="menu-toggle" (click)="toggleMobileNav()">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <!-- End Mobile Navigation -->

    <!-- Arrows Nav -->
    <div class="jsportf-arrows-nav">
      <div class="jsportf-arrow-right" (click)="nextPage()">
        <i class="lnr lnr-chevron-right"></i>
      </div>
      <div class="jsportf-arrow-left" (click)="previousPage()">
        <i class="lnr lnr-chevron-left"></i>
      </div>
    </div>

    <router-outlet></router-outlet>
  </div>
</div>
