<div class="content-area" [ngClass]="pageAnimationClasses">
  <div class="animated-sections">
    <section
      data-id="contact"
      class="animated-section start-page section-active"
    >
      <div class="page-title">
        <h2>Get in touch</h2>
      </div>

      <div class="section-content">
        <div class="row">
          <!-- Contact Info -->
          <div class="col-xs-12 col-sm-4">
            <div class="lm-info-block gray-default">
              <i class="lnr lnr-map-marker"></i>
              <h4>Boston</h4>
              <span class="lm-info-block-value"></span>
              <span class="lm-info-block-text"></span>
            </div>

            <div href="tel:+18572076907" class="lm-info-block gray-default">
              <i class="lnr lnr-phone-handset"></i>
              <h4>+1 857-207-6907</h4>
              <span class="lm-info-block-value"></span>
              <span class="lm-info-block-text"></span>
            </div>

            <div class="lm-info-block gray-default">
              <i class="lnr lnr-envelope"></i>
              <h4 href="mailto:sheth.j@northeastern.edu">
                sheth.j@northeastern.edu
              </h4>
              <span class="lm-info-block-value"></span>
              <span class="lm-info-block-text"></span>
            </div>
          </div>
          <!-- End of Contact Info -->

          <!-- Contact Form -->
          <div class="col-xs-12 col-sm-8">
            <!-- <div id="map" class="map"></div> -->
            <div class="block-title">
              <h3>How Can I <span>Help You?</span></h3>
            </div>

            <form [formGroup]="contactForm">
              <mat-form-field
                class="full-width"
                appearance="outline"
                color="accent"
              >
                <mat-label>Full Name</mat-label>
                <input
                  matInput
                  placeholder="Full Name"
                  formControlName="name"
                />
                <mat-error
                  *ngIf="
                    contactForm.get('name').touched &&
                    contactForm.get('name').hasError('required')
                  "
                >
                  Full Name is <strong>required.</strong>
                </mat-error>
              </mat-form-field>
              <mat-form-field
                class="full-width"
                appearance="outline"
                color="accent"
              >
                <mat-label>Email Address</mat-label>
                <input
                  matInput
                  placeholder="Email Address"
                  formControlName="email"
                />
                <mat-error
                  *ngIf="
                    contactForm.get('email').touched &&
                    contactForm.get('email').hasError('required')
                  "
                >
                  Email Address is <strong>required.</strong>
                </mat-error>
                <mat-error
                  *ngIf="
                    !contactForm.get('email').hasError('required') &&
                    contactForm.get('email').hasError('email')
                  "
                >
                  Email Address is <strong>invalid.</strong>
                </mat-error>
              </mat-form-field>
              <mat-form-field
                class="full-width"
                appearance="outline"
                color="accent"
              >
                <mat-label>Subject</mat-label>
                <input
                  matInput
                  placeholder="Subject"
                  formControlName="subject"
                />
              </mat-form-field>
              <mat-form-field
                class="full-width"
                appearance="outline"
                color="accent"
              >
                <mat-label>Message</mat-label>
                <textarea
                  matInput
                  placeholder="Message"
                  formControlName="message"
                  rows="6"
                ></textarea>
              </mat-form-field>
              <div class="text-center">
                <button class="button btn-send">Send Message</button>
              </div>
            </form>
          </div>
          <!-- End of Contact Form -->
        </div>
      </div>
    </section>
  </div>
</div>
