<div class="content-area" [ngClass]="pageAnimationClasses">
  <div class="animated-sections">
    <section data-id="about" class="animated-section start-page section-active">
      <div class="section-content">
        <div class="page-title">
          <h2>Projects <span>Showcase</span></h2>
        </div>
      </div>
      <div class="section-content">
        <mat-tab-group color="accent">
          <mat-tab label="Salesforce PWA Kit">
            <div class="block-title my-1">
              <h3 class="text-center">PWA <span>Kit</span></h3>
            </div>
            <div class="m-3">
              <h4 class="mb-2">
                The Progressive Web App Kit (PWA Kit) is a framework for
                creating storefronts with React.
              </h4>
              <p>With the PWA Kit framework you get:</p>
              <ul>
                <li>
                  Project templates, including the Retail React App. The Retail
                  React App is a customizable storefront that implements core
                  ecommerce flows from home page to checkout.
                </li>
                <li>
                  A rendering system that works on both the server side and
                  client side—and handles hydration (the process of transferring
                  rendering from server to client).
                </li>
                <li>
                  A routing system that allows you to inject data from the
                  Commerce API into your components.
                </li>
                <li>
                  Utility functions and scripts for automating routine
                  development tasks.
                </li>
              </ul>
            </div>

            <div class="block-title">
              <h3>Tech Stack</h3>
            </div>

            <ul class="knowledges">
              <li>React</li>
              <li>Typescript</li>
              <li>Javascript</li>
              <li>AWS Lambda</li>
              <li>PWA</li>
            </ul>

            <div class="text-center mt-5">
              <button
                class="button btn-send"
                (click)="openTab(showcaseURL.PWA_KIT)"
              >
                Learn More
              </button>
            </div>
          </mat-tab>

          <mat-tab label="I/O Sense">
            <div class="block-title my-1">
              <h3 class="text-center">I/O <span>Sense</span></h3>
            </div>
            <div class="m-3">
              <h4 class="mb-2">
                IoT platform that is built to drive accelerated digital
                transformation for enterprises.
              </h4>
              <p>
                I/O Sense is a comprehensive cloud based IoT software platform
                that is configured to cater multiple applications like energy
                management, water management, temperature monitoring etc. I/O
                Sense also serves as an Industrial Internet of Things (IIoT)
                platform to help in digitization & predictive analytics of
                processes and assets. Several enterprises have leveraged I/O
                Sense’s capabilities to drive visibility, automate their
                operations, incorporate process analytics & perform data-driven
                decision making.
              </p>
            </div>

            <div class="block-title">
              <h3>Tech Stack</h3>
            </div>

            <ul class="knowledges">
              <li>Angular</li>
              <li>NodeJs</li>
              <li>MongoDB</li>
              <li>Redis DB</li>
              <li>Influx DB</li>
              <li>PWA</li>
            </ul>

            <div class="text-center mt-5">
              <button
                class="button btn-send"
                (click)="openTab(showcaseURL.IOSENSE)"
              >
                Learn More
              </button>
            </div>
          </mat-tab>
          <mat-tab label="Slick Chat">
            <div class="block-title my-1">
              <h3 class="text-center">Slick <span>Chat</span></h3>
            </div>

            <div class="m-3">
              <p class="mb-2">
                A secure messaging system which focused on features similar to
                the popular messaging application - Slack. Features like group
                messaging, secure messaging, exchanging files, thread based
                conversations, and easily accessible front-end clients were at
                the centre of the project
              </p>
              <iframe width="420" height="350" [src]="embedURL"> </iframe>
            </div>

            <div class="block-title">
              <h3>Tech Stack</h3>
            </div>

            <ul class="knowledges">
              <li>Angular</li>
              <li>Java</li>
              <li>Spring Boot</li>
              <li>MongoDB</li>
              <li>JUnit Testing</li>
              <li>PWA</li>
            </ul>
          </mat-tab>
        </mat-tab-group>
      </div>
    </section>
  </div>
</div>
