<div class="content-area" [ngClass]="pageAnimationClasses">
  <div class="animated-sections">
    <section data-id="about" class="animated-section start-page section-active">
      <div class="section-content">
        <div class="page-title">
          <h2>About <span>Me</span></h2>
        </div>
      </div>
      <div class="section-content">
        <!-- Personal Information -->
        <div class="row">
          <div class="col-xs-12 col-sm-7">
            <p>
              I'm an experienced partner with a demonstrated history of working
              in the computer software industry. Skilled in Mobile Application
              Development, Front End Development, Backend Development, IoT data
              visualization and automation softwares. Strong development
              professional with a Masters in Computer Science from Northeastern
              University - Khoury College of Computer Sciences.
            </p>
          </div>

          <div class="col-xs-12 col-sm-5">
            <div class="info-list">
              <ul>
                <li>
                  <span class="title">Age</span>
                  <span class="value">{{ age }}</span>
                </li>

                <li>
                  <span class="title">Residence</span>
                  <span class="value">USA</span>
                </li>

                <li>
                  <span class="title">Address</span>
                  <span class="value">3600 Mystic Valley Pkwy</span>
                </li>

                <li>
                  <span class="title">e-mail</span>
                  <span class="value">sheth.j@northeastern.edu</span>
                </li>

                <li>
                  <span class="title">Phone</span>
                  <span class="value" href="tel:+18572076907"
                    >+1 (857)-207-6907</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- End of Personal Information -->

        <div class="white-space-50"></div>

        <!-- Services -->
        <div class="row">
          <div class="col-xs-12 col-sm-12">
            <div class="block-title">
              <h3>What <span>I Do</span></h3>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-6">
            <div class="col-inner">
              <div class="info-list-w-icon">
                <div class="info-block-w-icon">
                  <div class="ci-icon">
                    <i class="lnr lnr-store"></i>
                  </div>
                  <div class="ci-text">
                    <h4>Web Development - FrontEnd</h4>
                    <p>
                      Proficient in HTML, CSS, Javascript & Typescript with four
                      years of experience in data visualiztion and front-end web
                      development using Angular & React.
                    </p>
                  </div>
                </div>
                <div class="info-block-w-icon">
                  <div class="ci-icon">
                    <i class="lnr lnr-laptop-phone"></i>
                  </div>
                  <div class="ci-text">
                    <h4>Mobile Development & Hybrid Apps</h4>
                    <p>
                      Expirienced in building hybrid and native mobile
                      applications using Ionic Framework, React Native &
                      Flutter.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6">
            <div class="col-inner">
              <div class="info-list-w-icon">
                <div class="info-block-w-icon">
                  <div class="ci-icon">
                    <i class="lnr lnr-pencil"></i>
                  </div>
                  <div class="ci-text">
                    <h4>Web Development - BackEnd & Database Management</h4>
                    <p>
                      Built scalable, maintianable application servers using
                      Java (Spring MVC), Node.js, MongoDB, InfluxDB, RedisDB
                      while pairing apps with firebase, MQTT or Apache Kafka for
                      a realtime experience.
                    </p>
                  </div>
                </div>
                <div class="info-block-w-icon">
                  <div class="ci-icon">
                    <i class="lnr lnr-flag"></i>
                  </div>
                  <div class="ci-text">
                    <h4>Cloud Management</h4>
                    <p>
                      Skilled at managing cloud based software delivery
                      platforms like AWS (EC2, S3, etc)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End of Services -->

        <div class="white-space-50"></div>

        <!-- Employers -->
        <div class="row">
          <div class="col-xs-12 col-sm-12">
            <div class="block-title">
              <h3>Employers</h3>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6 col-lg-3">
            <div class="client-block vcentered">
              <div class="card">
                <div class="card-body">
                  <a
                    href="https://www.salesforce.com"
                    target="_blank"
                    title="salesforce"
                  >
                    <img
                      class="logo"
                      src="../../../assets/img/employers/salesforce.svg"
                      alt="salesforce"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-3">
            <div class="client-block vcentered">
              <div class="card">
                <div class="card-body">
                  <a
                    href="https://www.cvshealth.com"
                    target="_blank"
                    title="cvs"
                  >
                    <img
                      class="logo"
                      src="../../../assets/img/employers/cvs-health.png"
                      alt="cvs"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-3">
            <div class="client-block vcentered">
              <div class="card">
                <div class="card-body">
                  <a
                    href="https://www.northeastern.edu"
                    target="_blank"
                    title="neu"
                  >
                    <img
                      class="logo"
                      src="../../../assets/img/employers/neu.png"
                      alt="neu"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-3">
            <div class="client-block vcentered">
              <div class="card">
                <div class="card-body">
                  <a
                    href="https://www.faclon.com"
                    target="_blank"
                    title="faclon"
                  >
                    <img
                      class="logo"
                      src="../../../assets/img/employers/faclon.png"
                      alt="faclon"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-3">
            <div class="client-block vcentered">
              <div class="card">
                <div class="card-body">
                  <a
                    href="https://www.aurionpro.com"
                    target="_blank"
                    title="auironpro"
                  >
                    <img
                      class="logo"
                      src="../../../assets/img/employers/aurionpro.png"
                      alt="aurionpro"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End of Employers -->
      </div>
    </section>
  </div>
</div>
