import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { trigger, transition, style, animate } from "@angular/animations";

@Component({
  selector: "home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy {
  textRotationOptions: OwlOptions;
  pageAnimationClasses = "animate__animated animate__zoomIn";
  showSubText = false;

  constructor() {}

  ngOnInit(): void {
    this.textRotationOptions = {
      loop: true,
      dots: false,
      nav: false,
      margin: 0,
      items: 1,
      autoplay: true,
      autoplayHoverPause: false,
      autoplayTimeout: 3800,
      animateOut: "animated-section-scaleDown",
      animateIn: "animated-section-scaleUp",
    };

    setTimeout(() => {
      this.showSubText = true;
    }, 500);
  }

  ngOnDestroy(): void {
    this.pageAnimationClasses = "animate__animated animate__zoomOut";
  }
}
