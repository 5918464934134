import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
  selector: "contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
  pageAnimationClasses = "animate__animated animate__zoomIn";

  contactForm: FormGroup = new FormGroup({
    name: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.required, Validators.email]),
    subject: new FormControl("", []),
    message: new FormControl("", [Validators.required]),
  });

  constructor(private toastrService: ToastrService) {}

  ngOnInit(): void {}

  sendMessage() {
    this.toastrService.success(
      "Thanks for reaching out. I'll get back yo you ASAP!",
      "Message Sent"
    );
  }
}
