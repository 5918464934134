import { Component, OnInit } from "@angular/core";

@Component({
  selector: "resume",
  templateUrl: "./resume.component.html",
  styleUrls: ["./resume.component.scss"],
})
export class ResumeComponent implements OnInit {
  pageAnimationClasses = "animate__animated animate__zoomIn";

  constructor() {}

  ngOnInit(): void {}

  viewCertificate() {
    window.open("https://www.udemy.com/certificate/UC-T9SSJCZZ/");
  }
}
