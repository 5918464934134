import { Component, OnInit } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.scss"],
})
export class AboutComponent implements OnInit {
  pageAnimationClasses = "animate__animated animate__zoomIn";
  age: number;

  employerCarouselOptions: OwlOptions;

  constructor() {
    this.age = new Date().getFullYear() - 1995;
  }

  ngOnInit(): void {
    this.employerCarouselOptions = {
      nav: false, // Show next/prev buttons.
      items: 4, // The number of items you want to see on the screen.
      loop: false, // Infinity loop. Duplicate last and first items to get loop illusion.
      autoHeight: true,
      responsive: {
        // breakpoint from 0 up
        0: {
          items: 2,
        },
        // breakpoint from 768 up
        768: {
          items: 4,
        },
        1200: {
          items: 5,
        },
      },
    };
  }
}
