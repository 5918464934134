<div class="content-area" [ngClass]="pageAnimationClasses">
  <div class="animated-sections">
    <section data-id="home" class="animated-section start-page section-active">
      <div class="section-content vcentered">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="title-block">
              <h2>Jainam Sheth</h2>
              <div class="text-rotation" *ngIf="showSubText">
                <owl-carousel-o [options]="textRotationOptions">
                  <ng-template carouselSlide>
                    <div class="sp-subtitle">Full Stack Developer</div>
                  </ng-template>
                  <ng-template carouselSlide>
                    <div class="sp-subtitle">
                      Software Engineer MTS at Salesforce
                    </div>
                  </ng-template>
                  <ng-template carouselSlide>
                    <div class="sp-subtitle">
                      MS in Computer Science, Northeastern University
                    </div>
                  </ng-template>
                </owl-carousel-o>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
