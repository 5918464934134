import { SafeResourceUrl } from "@angular/platform-browser";

export enum AppTheme {
  DARK,
  LIGHT,
}

export const SHOWCASE_URL = {
  IOSENSE: "https://www.faclon.com/iosense.html",
  SLICK_CHAT_YT: "https://www.youtube.com/embed/hmUEypnCPQY",
  SLICK_CHAT_GITHUB:
    "https://github.ccs.neu.edu/cs5500-fse/team-2-SP20/blob/master/final/report.pdf",
  PWA_KIT: "https://pwa-kit.mobify-storefront.com/",
};
